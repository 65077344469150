import React from "react";
import {
  Achievements,
  PandaC,
  Rewards,
  Scaling,
  Telegram,
  TelegramJoin,
  X,
} from "../Assets";

export default function Footer() {
  return (
    <main id="contact">
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-7xl md:px-0 lg:px-20 lg:py-10 flex flex-col justify-center gap-10 items-center h-full">
        <div className="grid gap-5 row-gap-8 lg:grid-cols-2">
          <div className="flex flex-col justify-center gap-3 items-center">
            <div className="max-w-xl mb-6 flex flex-col justify-center items-center">
              <h2 className="max-w-lg mb-6 font-sans font-bold tracking-tight text-design neugro__font lg:text-[20px] text-[12px] sm:leading-none text-center mt-20">
                The Real Adventure Starts Here
              </h2>
              <p className="text-white lg:text-xl text-sm font-bold text-center meregular__font uppercase">
                Join our vibrant community of $PNDA holders
              </p>
            </div>
            <div className="flex flex-col justify-center items-center gap-2">
              <a
                href="https://t.me/pandianaonsol"
                className="w-60 p-2 cursor-pointer hover:opacity-90"
                target="blank"
              >
                <img src={TelegramJoin} alt="Telegram" className="w-auto" />
              </a>
              <a
                href="https://x.com/pandianaonsol"
                target="blank"
                className="hover:opacity-90 w-12 cursor-pointer z-[100]"
              >
                <img src={X} alt="X" className="w-auto" />
              </a>
            </div>
            <div className="flex justify-center lg:justify-end items-center w-full lg:ml-40 lg:-mt-28 z-50">
              <img src={PandaC} alt="Panda" className="lg:w-auto w-60" />
            </div>
          </div>
          <div className="lg:mt-0 lg:ml-0 -mt-10">
            <div className="px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-0 lg:px-8 lg:py-10 blurBGG">
              <h1 className="text-center font-bold lg:text-xl tet-sm mb-5 text-white meregular__font uppercase">
                Together, we'll carve out our niche in the digital wilderness
              </h1>
              <div className="grid gap-6 row-gap-10 lg:grid-cols-1">
                <div className="lg:py-6 lg:pr-16">
                  <div className="flex">
                    <div className="flex flex-col items-center mr-4">
                      <div>
                        <div className="flex items-center justify-center w-10 h-10 border rounded-full">
                          <img src={Rewards} alt="rewards" />
                        </div>
                      </div>
                    </div>
                    <div className="pt-1 pb-8">
                      <p className="mb-2 text-lg font-bold text-white neugro__font">
                        Earning Rewards
                      </p>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="flex flex-col items-center mr-4">
                      <div>
                        <div className="flex items-center justify-center w-10 h-10 border rounded-full">
                          <img src={Achievements} alt="Achievements" />
                        </div>
                      </div>
                    </div>
                    <div className="pt-1 pb-8">
                      <p className="mb-2 text-lg font-bold text-white neugro__font">
                        Unlocking Achievements
                      </p>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="flex flex-col items-center mr-4">
                      <div>
                        <div className="flex items-center justify-center w-10 h-10 border rounded-full">
                          <img src={Scaling} alt="scaling" />
                        </div>
                      </div>
                    </div>
                    <div className="pt-1 pb-8">
                      <p className="mb-2 text-lg font-bold text-white neugro__font">
                        Scaling New Heights
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="lg:flex grid lg:gap-10 items-center">
          <p className="text-white neugro__font lg:text-[20px] text-[15px] lg:text-start text-center">
            ©2024 Pandiana
          </p>
          <a
            href="mailto:support@pandiana.io"
            className="text-white neugro__font cursor-pointer lg:text-[20px] text-[15px] lg:text-start text-center"
          >
            support@pandiana.io
          </a>
        </footer>
      </div>
    </main>
  );
}

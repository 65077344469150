import React, { useState, useEffect, useRef } from "react";
import "./Carousel.css";
import { Left, Right } from "../Assets";
import Popup from "./PopupBefore";
import { BuyPopup } from ".";
import PopupBefore from "./PopupBefore";
import PopupAfter from "./PopupAfter";

export default function Carousel(props) {
  const [currentIndex, setCurrentIndex] = useState(3);
  const carouselRef = useRef(null);
  const [showPopupBefore, setShowPopupBefore] = useState(false);
  const [showPopupAfter, setShowPopupAfter] = useState(false);
  const [showBuyPopup, setShowBuyPopup] = useState(false);

  const handlePopupBefore = (e) => {
    e.preventDefault();
    setShowPopupBefore(true);
  };

  const closePopupBefore = () => {
    setShowPopupBefore(false);
  };

  const handlePopupAfter = (e) => {
    e.preventDefault();
    setShowPopupAfter(true);
  };

  const closePopupAfter = () => {
    setShowPopupAfter(false);
  };

  const handleBuyPopup = (e) => {
    e.preventDefault();
    setShowBuyPopup(true);
  };

  const closeBuyPopup = () => {
    setShowBuyPopup(false);
  };

  useEffect(() => {
    const handleTransitionEnd = () => {
      if (currentIndex === 0) {
        setCurrentIndex(props.images.length);
      } else if (currentIndex === props.images.length + 1) {
        setCurrentIndex(1);
      }
    };

    const carousel = carouselRef.current;
    carousel.addEventListener("transitionend", handleTransitionEnd);

    return () => {
      carousel.removeEventListener("transitionend", handleTransitionEnd);
    };
  }, [currentIndex, props.images.length]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => prevIndex - 1);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  const getTransformStyle = () => {
    return `translateX(calc(50% - ${
      currentIndex * (90 / (props.images.length + 2))
    }% - ${currentIndex * 50}px))`;
  };

  return (
    <main>
      <div className="carousel-container">
        <div className="carousel-wrapper">
          <div
            className="carousel"
            ref={carouselRef}
            style={{
              transform: getTransformStyle(),
              transition: "transform 0.5s ease",
            }}
          >
            <div className="carousel-item">
              <img
                src={props.images[props.images.length - 1].src}
                className="carousel-image"
                alt={props.images[props.images.length - 1].alt}
              />
              <p className="carousel-caption">
                {props.images[props.images.length - 1].caption}
              </p>
            </div>
            {props.images.map((image, index) => (
              <div
                key={index}
                className={`carousel-item ${
                  index === currentIndex - 1 ? "center" : ""
                }`}
              >
                <img
                  src={image.src}
                  className="carousel-image"
                  alt={image.alt}
                />
                <p className="carousel-caption">{image.caption}</p>
              </div>
            ))}
            <div className="carousel-item">
              <img
                src={props.images[0].src}
                className="carousel-image"
                alt={props.images[0].alt}
              />
              <p className="carousel-caption">{props.images[0].caption}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center items-center mt-3">
        <button className="bg-primaryColor w-40 lg:p-3 p-2 mt-5 rounded-xl hover:opacity-90" onClick={(props.timeHasFinished & !props.preSaleEndTimeHasFinished) ? handleBuyPopup : ((props.timeHasFinished & props.preSaleEndTimeHasFinished) ? handlePopupAfter : handlePopupBefore)}>
          <a
            className="text-center meregular__font text-design lg:text-[10px] text-[8px]"
          >
            Buy $PNDA
          </a>
        </button>
      </div>

      <div className="flex justify-center items-center mt-10 gap-20 mb-32">
        <a className="cursor-pointer hover:opacity-90" onClick={handlePrev}>
          <img src={Left} alt="Left" className="lg:w-12 w-10" />
        </a>
        <a className="cursor-pointer hover:opacity-90" onClick={handleNext}>
          <img src={Right} alt="Right" className="lg:w-12 w-10" />
        </a>
      </div>
      
      <PopupBefore show={showPopupBefore} onClose={closePopupBefore} />
      <PopupAfter show={showPopupAfter} onClose={closePopupAfter}  />
      <BuyPopup show={showBuyPopup} onClose={closeBuyPopup} />

    </main>
  );
}

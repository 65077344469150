import React, { useEffect } from "react";
import "../App.css";
import { Copy, Discord, QRCode, Telegram, X, XClose } from "../Assets";

export default function PopupAfter({ show, onClose }) {
  if (!show) {
    return null;
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      const popupContent = document.getElementById("popup-content");
      if (popupContent && !popupContent.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="p-20 z-50 mt-20 ">
      <div className=" rounded-lg px-10" id="popup-overlay">
        <div className=" rounded-lg lg:py-5 lg:px-[20px] px-[25px] py-[20px] lg:w-[35%] w-auto h-auto" id="popup-content">
          <div className="flex justify-end" id="close-btn">
            <a onClick={onClose}><img src={XClose} alt="closebtn" /></a>
          </div>
          <div className="flex flex-col gap-5 justify-between items-center">
            <h1 className="uppercase text-2xl text-goldShade text-center meregular__font">PRESALE HAS ENDED!</h1>
            <p className="text-design text-center lg:w-[400px] w-[280px] neugro__font text-xs">
              
            </p>
            <h2 className="text-xl uppercase text-white text-design meregular__font text-center">KINDLY JOIN OUR COMMUNITY BELOW FOR ANNOUNCEMENTS</h2>
            <div className="flex items-center gap-5 mt-5">
              <a href="https://t.me/pandianaonsol">
                <img src={Telegram} alt="Telegram" className="w-12" />
              </a>
              <a href="https://discord.gg/pandiana">
                <img src={Discord} alt="Discord" className="w-12" />
              </a>
              <a href="https://x.com/pandianaonsol">
                <img src={X} alt="X" className="w-12" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Body, Footer, Header } from "../UI";

export default function Home() {
  const calculateTimeLeft = () => {
    const targetDate = '2024-07-04T15:00:00Z';
    const now = new Date();
    const target = new Date(targetDate);
    const difference = target.getTime() - now.getTime();

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const timeHasFinished = timeLeft.days == undefined && timeLeft.hours == undefined && timeLeft.minutes == undefined && timeLeft.seconds == undefined;

  const preSaleEndDate = '2024-09-02T16:00:00Z';

  const calculatePreSaleEndTimeLeft = () => {
      const now = new Date();
      const target = new Date(preSaleEndDate);
      const difference = target.getTime() - now.getTime();
      let timeLeft = {};
  
      if (difference > 0) {
        timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60)
        };
      }
  
      return timeLeft;
    };
  
    const [preSaleEndTime, setPreSaleEndTime] = useState(calculatePreSaleEndTimeLeft());

    useEffect(() => {
      const timer = setTimeout(() => {
          setPreSaleEndTime(calculatePreSaleEndTimeLeft());
      }, 1000);
  
      return () => clearTimeout(timer);
    });

    const preSaleEndTimeHasFinished = preSaleEndTime.days == undefined && preSaleEndTime.hours == undefined && preSaleEndTime.minutes == undefined && preSaleEndTime.seconds == undefined;


  return (
    <main>
      <Header timeLeft={timeLeft} timeHasFinished={timeHasFinished} preSaleEndTimeHasFinished={preSaleEndTimeHasFinished} preSaleEndTime={preSaleEndTime} />
      <Body timeLeft={timeLeft} timeHasFinished={timeHasFinished} preSaleEndTimeHasFinished={preSaleEndTimeHasFinished} preSaleEndTime={preSaleEndTime} />
      <Footer />
    </main>
  );
}

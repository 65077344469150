import React, { useEffect } from "react";
import "../App.css";
import { Copy, Discord, QRCode, Telegram, X, XClose } from "../Assets";

export default function Popup({ show, onClose }) {
  if (!show) {
    return null;
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      const popupContent = document.getElementById("popup-content");
      if (popupContent && !popupContent.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const copyToClipboard = () => {
    const input = document.getElementById("copyInput");
    input.select();
    input.setSelectionRange(0, 99999);
    document.execCommand("copy");
  };

  return (
    <div className="p-20 z-50 mt-20 ">
      <div className=" rounded-lg px-10" id="popup-overlay">
        <div className=" rounded-lg lg:py-5 lg:px-[20px] px-[25px] py-[20px] lg:w-[35%] w-auto h-auto" id="popup-content">
          <div className="flex justify-end" id="close-btn">
            <a onClick={onClose}><img src={XClose} alt="closebtn" /></a>
          </div>
          <div className="flex flex-col gap-5 justify-between items-center">
            <h1 className="uppercase text-sm text-goldShade text-center meregular__font">Buy $pnda token</h1>
            <p className="text-design text-center lg:w-[400px] w-[280px] neugro__font text-xs">
              Copy The Presale Address Below And Send SOL, You Can Also Scan The QR Code.
            </p>
            <img className="w-32" src="https://api.qrserver.com/v1/create-qr-code/?size=256x256&amp;data=7U1caoVr4Gy4jHF2YJLU4mGAJY3cTwThBdVh5Mnp3iZi" alt="" />
            <div className="flex justify-between items-center bg-darkColor p-2 rounded-full">
              <input id="copyInput" value="7U1caoVr4Gy4jHF2YJLU4mGAJY3cTwThBdVh5Mnp3iZi" readOnly className="outline-none border-collapse bg-darkColor text-white meregular__font text-xs" />
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  copyToClipboard();
                }} 
              >
                <img src={Copy} alt="copy" className="w-5" />
              </a>
            </div>
            <h1 className="text-sm uppercase text-design meregular__font">How to buy</h1>
            <div className="text-white list-decimal text-justify text-center items-center justify-center flex flex-col gap-1 neugro__font text-xs">
              <p><span className="text-goldShade text-center">Set Up</span> Solana Wallet (Solflare Or Phantom Works)</p>
              <p><span className="text-goldShade text-center">Buy</span> SOL From Binance Or Coinbase</p>
              <p><span className="text-goldShade text-center">Transfer</span> The Sol To Your Solana Wallet</p>
              <p>Visit<span className="text-goldShade text-center"><a href="https://pandiana.io"> Pandiana.io</a></span> Website, Click On Buy $PNDA</p>
              <p><span className="text-goldShade text-center">Copy</span> The Presale Address & Send SOL.</p>
            </div>
            <p className="text-design text-center lg:w-[400px] w-[280px] neugro__font text-xs">
              Your $PNDA Tokens Will Be Airdropped To Your Wallet Once Presale Ends
            </p>
            <h2 className="text-[10px] uppercase text-white text-design meregular__font text-center">Join our community if you have any enquires</h2>
            <div className="flex items-center gap-5 mt-5">
              <a href="https://t.me/pandianaonsol">
                <img src={Telegram} alt="Telegram" className="w-6" />
              </a>
              <a href="https://discord.gg/pandiana">
                <img src={Discord} alt="Discord" className="w-6" />
              </a>
              <a href="https://x.com/pandianaonsol">
                <img src={X} alt="X" className="w-6" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import "../App.css";
import { BgA, Logo, MenuBtn } from "../Assets";
import Hero from "./Hero";

export default function Header(props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <main className="w-full h-auto" id="bgimgA">
      <div className="px-5 py-10 mx-auto max-w-7xl md:max-w-full lg:max-w-screen-7xl md:px-10 lg:px-40">
        <div className="flex items-center justify-between">
          <a
            href="/"
            aria-label="Pandiana"
            title="Pandiana"
            className="inline-flex items-center"
          >
            <img src={Logo} alt="Logo" className="lg:w-32 w-24" />
          </a>
          <ul className="flex items-center hidden space-x-8 lg:flex">
            <li>
              <a
                href="https://docs.pandiana.io/buy-pnda-token"
                aria-label="Buy"
                className="font-medium tracking-wide text-white transition-colors duration-200 hover:text-gray-200 neugro__font cursor-pointer lg:text-[15px] text-[10px]"
                target="_blank"
              >
                How To Buy $PNDA
              </a>
            </li>
            <li>
              <a
                href="https://docs.pandiana.io"
                aria-label="Whitepaper"
                className="font-medium tracking-wide text-white transition-colors duration-200 hover:text-gray-200 neugro__font cursor-pointer lg:text-[15px] text-[10px]"
                target="blank"
              >
                Whitepaper
              </a>
            </li>
            <li>
              <a
                href="#pandanomics"
                aria-label="Pandanomics"
                className="font-medium tracking-wide text-white transition-colors duration-200 hover:text-gray-200 neugro__font cursor-pointer lg:text-[15px] text-[10px]"
              >
                Pandanomics
              </a>
            </li>
            <li>
              <a
                href="#contact"
                aria-label="Contact"
                className="font-medium tracking-wide text-white transition-colors duration-200 hover:text-gray-200 neugro__font cursor-pointer lg:text-[15px] text-[10px]"
              >
                Contact
              </a>
            </li>
          </ul>
          <div className="lg:hidden">
            <button
              aria-label="Open Menu"
              className="p-2 -mr-1 transition duration-200 rounded focus:outline-none "
              onClick={() => setIsMenuOpen(true)}
            >
              <img src={MenuBtn} alt="Menu" className="w-5" />
            </button>
            <div>
              <div
                className={`m-2 px-5 pb-40 rounded-2xl h-40 pt-10 overscroll-y-contain absolute top-0 z-50 left-0 w-[97%] bg-black ${
                  isMenuOpen ? "open" : "close"
                }`}
                id="transition-menu"
              >
                <div className="mt-5 px-5 rounded-2xl h-32 pt-5 overflow-auto absolute top-0 left-0 w-[97%] z-20">
                  <div className="flex items-center justify-between mb-4">
                    <div>
                      <a
                        href="/"
                        aria-label="Pandiana"
                        title="Pandiana"
                        className="inline-flex items-center meregular__font text-white text-[18px]"
                      >
                        Menu
                      </a>
                    </div>
                    <div>
                      <button
                        aria-label="Close Menu"
                        className="p-2 -mt-2 -mr-2 rounded transition duration-200"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        <svg className="w-5 text-white" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <nav>
                    <ul className="space-y-4">
                      <li>
                        <a
                          href="https://docs.pandiana.io/buy-pnda-token"
                          aria-label="Buy"
                          className="tracking-wide font-normal capitalize text-white transition-colors duration-200 hover:text-gray-200 meregular__font text-[13px]"
                          target="_blank"
                        >
                          How To Buy $PNDA
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://docs.pandiana.io"
                          aria-label="Whitepaper"
                          className="font-medium tracking-wide capitalize text-white transition-colors duration-200 hover:text-gray-200 meregular__font text-[13px]"
                          target="blank"
                        >
                          Whitepaper
                        </a>
                      </li>
                      <li>
                        <a
                          href="#pandanomics"
                          aria-label="Product pricing"
                          className="font-medium tracking-wide capitalize text-white transition-colors duration-200 hover:text-gray-200 meregular__font text-[13px]"
                        >
                          Pandanomics
                        </a>
                      </li>
                      <li>
                        <a
                          href="#contact"
                          aria-label="About us"
                          className="font-medium tracking-wide capitalize text-white transition-colors duration-200 hover:text-gray-200 meregular__font text-[13px]"
                        >
                          Contact
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Hero timeLeft={props.timeLeft} timeHasFinished={props.timeHasFinished} preSaleEndTimeHasFinished={props.preSaleEndTimeHasFinished} preSaleEndTime={props.preSaleEndTime} />
    </main>
  );
}

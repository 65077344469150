import React, { useState, useEffect } from "react";

export default function Timer(props) {
  // const calculateTimeLeft = () => {
  //   const targetDate = new Date("July 4, 2024 16:00:00 GMT+0100").getTime();
  //   const now = new Date().getTime();
  //   const difference = targetDate - now;

  //   let timeLeft = {};

  //   if (difference > 0) {
  //     timeLeft = {
  //       days: Math.floor(difference / (1000 * 60 * 60 * 24)),
  //       hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
  //       minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
  //       seconds: Math.floor((difference % (1000 * 60)) / 1000),
  //     };
  //   }

  //   return timeLeft;
  // };

  // const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setTimeLeft(calculateTimeLeft());
  //   }, 1000);

  //   return () => clearInterval(interval);
  // }, []);
 
  return (
    <div className="btnCta rounded-[20px] flex justify-between items-center lg:p-3 p-2">
      <div className="flex flex-col justify-center items-center gap-2">
        <p className="text-white meregular__font lg:text-[7px] text-[7px] leading-[8px]">DAYS</p>
        <p className="lg:text-[20px] text-[10px] font-extrabold text-white meregular__font">{ (props.timeHasFinished & !props.preSaleEndTimeHasFinished) ? props.preSaleEndTime.days : (props.timeLeft.days || '0') }</p>
      </div>
      <div className="flex flex-col justify-center items-center gap-2">
        <p className="text-white meregular__font lg:text-[7px] text-[7px]">HOURS</p>
        <p className="lg:text-[20px] text-[10px] font-extrabold text-white meregular__font">{ (props.timeHasFinished & !props.preSaleEndTimeHasFinished) ? props.preSaleEndTime.hours : (props.timeLeft.hours || '0') }</p>
      </div>
      <div className="flex flex-col justify-center items-center gap-2">
        <p className="text-white meregular__font lg:text-[7px] text-[7px]">MINUTES</p>
        <p className="lg:text-[20px] text-[10px] font-extrabold text-white meregular__font">{ (props.timeHasFinished & !props.preSaleEndTimeHasFinished) ? props.preSaleEndTime.minutes : (props.timeLeft.minutes || '0') }</p>
      </div>
      <div className="flex flex-col justify-center items-center gap-2">
        <p className="text-white meregular__font lg:text-[7px] text-[7px]">SECONDS</p>
        <p className="lg:text-[20px] text-[10px] font-extrabold text-white meregular__font">{ (props.timeHasFinished & !props.preSaleEndTimeHasFinished) ? props.preSaleEndTime.seconds : (props.timeLeft.seconds || '0') }</p>
      </div>
    </div>
  );
};

